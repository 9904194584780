<!-- nft市场 -->
<script setup>
import { onMounted, reactive, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useAppStore } from '@store/appStore';
import { ElMessage } from 'element-plus';
import i18n from '@/utils/i18n';
import { useWrite } from '@/hooks/useAction';
import Guild from '@/contractsApi/polygon/guild';

const $t = i18n.global.t;

const appStore = useAppStore();
let guildContract = new Guild();

const type = ref('guild'); // guild || elites

const changeTab = value => {
  type.value = value;
};

// 当前用户信息
const userInfo = ref({
  isRefer: false,
  referAmount: 0,
  claimed: 0, // 已领取
  toChaim: 0, //可领取
});

// 白名单总量
const total = ref(0);

async function init() {
  // 没有链接钱包只获取白名单总量
  if (appStore.defaultAccount) {
    const user = await guildContract.getUserInfo();
    userInfo.value = user;
    const toChaim = await guildContract.getUserToClaim();
    userInfo.value.toChaim = toChaim;
    const count = await guildContract.totalWhite();
    total.value = count >= 20 ? 0 : 20 - count;

    console.log(userInfo);
  } else {
    const count = await guildContract.totalWhite();
    total.value = count >= 20 ? 0 : 20 - count;
  }
}

// 领取奖励
async function getReward(reward) {
  const rewardMapIndex = [100, 500, 1000, 2000];
  // 是否有资格  && 查询可领取奖励
  if (!userInfo.value.isRefer) {
    ElMessage.error($t('msg.56'));
    return;
  }
  if (userInfo.value.toChaim <= 0) {
    ElMessage.error($t('msg.57'));
    return;
  }
  // 待领取金额 > 点击按钮 阶段奖励
  if (userInfo.value.toChaim >= reward) {
    const index = rewardMapIndex.indexOf(reward);
    if (index === -1) return;
    await guildContract.claimReward(index);
    init();
  }

  if (userInfo.value.toChaim >= 2000 && userInfo.value.claimed >= 2000) {
    ElMessage.error($t('msg.58'));
  }
}

// 为了不复用同一个loading
const [claimReward1, loading1] = useWrite(async reward => {
  await getReward(reward);
});

const [claimReward2, loading2] = useWrite(async reward => {
  await getReward(reward);
});

const [claimReward3, loading3] = useWrite(async reward => {
  await getReward(reward);
});

const [claimReward4, loading4] = useWrite(async reward => {
  await getReward(reward);
});

watch(
  () => appStore.defaultAccount,
  () => {
    guildContract = new Guild();
    init();
  },
  {
    immediate: true,
  }
);

console.log(appStore.lang, 'appStore.lang');
</script>

<template>
  <div :class="['guild-container']">
    <div class="banner">
      <img
        v-if="appStore.lang == 'en'"
        src="https://game.legendranch.app/legendaryranch/banner/guild-banner-en.webp"
        alt=""
      />
      <img
        v-else-if="appStore.lang == 'cn'"
        src="https://game.legendranch.app/legendaryranch/banner/guild-banner-cn.webp"
        alt=""
      />
      <img
        v-else-if="appStore.lang == 'kn'"
        src="https://game.legendranch.app/legendaryranch/banner/guild-banner-kn.webp"
        alt=""
      />

      <div class="btn-group">
        <div class="guild-btn" @click="changeTab('guild')">
          <img v-if="appStore.lang == 'en'" src="@img/activity/guild/guild-btn-en.png" alt="" />
          <img v-if="appStore.lang == 'cn'" src="@img/activity/guild/guild-btn-cn.png" alt="" />
          <img v-if="appStore.lang == 'kn'" src="@img/activity/guild/guild-btn-kn.png" alt="" />
        </div>
        <div class="user-btn" @click="changeTab('elites')">
          <img v-if="appStore.lang == 'en'" src="@img/activity/guild/user-btn-en.png" alt="" />
          <img v-if="appStore.lang == 'cn'" src="@img/activity/guild/user-btn-cn.png" alt="" />
          <img v-if="appStore.lang == 'kn'" src="@img/activity/guild/user-btn-kn.png" alt="" />
        </div>
      </div>
    </div>
    <main>
      <div v-show="type === 'guild'" class="guild-wrap">
        <div class="step-1 step">
          <div class="step-header">
            <div class="desc">
              <div class="title">{{ $t('guild.3') }}</div>
              <div class="tip">
                <span>{{ $t('guild.4') }}</span>
                <span>{{ $t('guild.6', [total]) }}</span>
              </div>
            </div>
            <img src="@img/activity/guild/step-1.png" alt="" />
          </div>
          <div class="content">
            <p>{{ $t('guild.5') }}</p>
            <p>{{ $t('guild.38') }}</p>
            <p>{{ $t('guild.39') }}</p>
            <p>{{ $t('guild.40') }}</p>
          </div>
          <div class="btn-group">
            <a class="btn-link" href="https://www.bovine-verse.games/betaAssets" target="_blank">
              {{ $t('guild.7') }}
            </a>
            <a
              class="btn-link"
              href="https://docs.google.com/forms/d/e/1FAIpQLSe_ZD_gAiRlK_vZbBfE_Ht12G6hNKGoesxLTYaB3cwxynMmEg/viewform"
              target="_blank"
            >
              {{ $t('guild.8') }}
            </a>
          </div>
        </div>
        <div class="step-2 step">
          <div class="step-header">
            <div class="desc">
              <div class="title">{{ $t('guild.9') }}</div>
              <div class="tip">
                <span>{{ $t('guild.10') }}</span>
              </div>
            </div>
            <img src="@img/activity/guild/step-2.png" alt="" />
          </div>
          <div class="content">
            <p>{{ $t('guild.11') }}</p>
            <p>{{ $t('guild.12') }}</p>
            <p>
              {{ $t('guild.13') }}
            </p>
            <p>{{ $t('guild.14') }}</p>
            <p>{{ $t('guild.15') }}</p>
            <p>
              {{ $t('guild.16') }}
            </p>
          </div>
          <div class="btn-group">
            <a
              class="btn-link"
              href="https://docs.google.com/forms/d/e/1FAIpQLScHm61Y3cKWYLvhsZrihat98ZwqEsmyUnr_zQfUU19Bwfb5lQ/viewform"
              target="_blank"
            >
              {{ $t('guild.37') }}
            </a>
          </div>
        </div>
        <!-- STEP 3 -->
        <div class="step-3">
          <div class="step-header">
            <div class="desc">
              <div class="title">{{ $t('guild.17') }}</div>
              <div class="tip">
                <span>{{ $t('guild.18') }}</span>
              </div>
            </div>
            <img src="@img/activity/guild/step-3.png" alt="" />
          </div>
          <div class="step-3-task">
            <div class="task-item">
              <div class="sub-title">{{ $t('guild.19') }}</div>
              <div class="arrow-wrap">
                <div class="line"></div>
                <div class="triangle"></div>
              </div>
              <div class="step-desc">
                {{ $t('guild.20') }}
              </div>
              <el-button
                :disabled="userInfo.toChaim < 100 || userInfo.claimed >= 100"
                :loading="loading1"
                @click="claimReward1(100)"
                class="btn-link"
              >
                {{ userInfo.claimed >= 100 ? $t('guild.41') : $t('guild.27') }}
              </el-button>
            </div>
            <div class="task-item">
              <div class="sub-title">{{ $t('guild.21') }}</div>
              <div class="arrow-wrap">
                <div class="line"></div>
                <div class="triangle"></div>
              </div>
              <div class="step-desc">
                {{ $t('guild.22') }}
              </div>
              <el-button
                :disabled="userInfo.toChaim < 500 || userInfo.claimed >= 500"
                :loading="loading2"
                @click="claimReward2(500)"
                class="btn-link"
              >
                {{ userInfo.claimed >= 500 ? $t('guild.41') : $t('guild.27') }}
              </el-button>
            </div>

            <div class="task-item">
              <div class="sub-title">
                {{ $t('guild.23') }}
              </div>
              <div class="arrow-wrap">
                <div class="line"></div>
                <div class="triangle"></div>
              </div>
              <div class="step-desc">
                {{ $t('guild.24') }}
              </div>
              <div class="badge">
                <img src="@img/activity/guild/Badge-2.png" alt="" />
              </div>
              <div class="btn-group">
                <el-button
                  :disabled="userInfo.toChaim < 1000 || userInfo.claimed >= 1000"
                  :loading="loading3"
                  @click="claimReward3(1000)"
                  class="btn-link"
                >
                  {{ userInfo.claimed >= 1000 ? $t('guild.41') : $t('guild.27') }}
                </el-button>
                <a
                  class="btn-link"
                  href="https://galaxy.eco/BovineVerse/campaign/GCTmfUtXYq"
                  target="_blank"
                >
                  {{ $t('guild.28') }}
                </a>
              </div>
            </div>

            <div class="task-item">
              <div class="sub-title">
                {{ $t('guild.25') }}
              </div>
              <div class="arrow-wrap">
                <div class="line"></div>
                <!-- <div class="triangle"></div> -->
              </div>
              <div class="step-desc">
                {{ $t('guild.26') }}
              </div>
              <div class="badge">
                <img src="@img/activity/guild/Badge-1.png" alt="" />
              </div>
              <div class="btn-group">
                <el-button
                  :disabled="userInfo.toChaim < 2000 || userInfo.claimed >= 2000"
                  :loading="loading4"
                  @click="claimReward4(2000)"
                  class="btn-link"
                >
                  {{ userInfo.claimed >= 2000 ? $t('guild.41') : $t('guild.27') }}
                </el-button>
                <a
                  class="btn-link"
                  href="https://galaxy.eco/BovineVerse/campaign/GC4efUtQmE"
                  target="_blank"
                >
                  {{ $t('guild.28') }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 精英 -->
      <div v-show="type === 'elites'" class="user-wrap">
        <div class="step-1 step">
          <div class="step-header">
            <div class="desc">
              <div class="title">{{ $t('guild.3') }}</div>
              <div class="tip">
                <span>{{ $t('guild.29') }}</span>
              </div>
            </div>
          </div>
          <div class="content">
            <p>{{ $t('guild.30') }}</p>
            <p>{{ $t('guild.31') }}</p>
            <p>{{ $t('guild.32') }}</p>
            <p>{{ $t('guild.33') }}</p>
            <p>{{ $t('guild.34') }}</p>
            <p>{{ $t('guild.35') }}</p>
          </div>
          <div class="btn-group">
            <a class="btn-link" href="https://www.bovine-verse.games/betaAssets" target="_blank">
              {{ $t('guild.7') }}
            </a>
            <a
              class="btn-link"
              href="https://docs.google.com/forms/d/e/1FAIpQLSeHwMd60e9ELEzGyMfFlleIqDYjzt2NbZ1TAwxuOVFvv5yptA/viewform"
              target="_blank"
            >
              {{ $t('guild.8') }}
            </a>
          </div>
        </div>

        <div class="step-2 step">
          <div class="step-header">
            <div class="desc">
              <div class="title">{{ $t('guild.9') }}</div>
              <div class="tip">
                <span>{{ $t('guild.36') }}</span>
              </div>
            </div>
          </div>
          <div class="badge-group">
            <img src="@img/activity/guild/Badge-3.png" alt="" />
          </div>
          <div class="btn-group">
            <a
              class="btn-link"
              href="https://galaxy.eco/BovineVerse/campaign/GCdqfUtmJn"
              target="_blank"
            >
              {{ $t('guild.28') }}
            </a>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<style lang="scss" scoped>
// 页面按钮通用样式
:deep(.btn-link) {
  @include flexPos(center);
  width: 2.57rem;
  height: 0.5rem;
  font-size: 0.2rem;
  font-weight: 600;
  color: #7847d6;
  line-height: 0.28rem;
  background: #ffffff;
  border-radius: 0.35rem;
  backdrop-filter: blur(40px);
  padding: 0;
  min-height: initial;
  &.is-disabled {
    background-color: #acacac;
    color: #fff;
    border: none;
  }
  @media (max-width: $phone) {
    font-size: 0.28rem;
    line-height: 0.2rem;
    width: 2.57rem;
    height: 0.5rem;
  }
}

.guild-container {
  width: 100%;
  background-image: url(https://game.legendranch.app/legendaryranch/background/bg-farm.png);
  background-size: 100% 100%;
  color: #fff;
  overflow: hidden;
  .banner {
    position: relative;
    > img {
      display: block;
      width: 100%;
      // min-width: 1920px;
      object-fit: cover;
      @media (max-width: $phone) {
        height: 5.4rem;
      }
    }

    .btn-group {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      @include flexPos(center);
      transform: translateY(100%);

      @media (max-width: $phone) {
        bottom: -0.38rem;
        transform: translateY(100%);
      }
    }
    .guild-btn {
      margin-right: 0.96rem;
      cursor: pointer;
      @media (max-width: $phone) {
        margin-right: 0.6rem;
      }
      img {
        width: 2.54rem;
        @media (max-width: $phone) {
          width: 2.54rem;
        }
      }
    }

    .user-btn {
      cursor: pointer;
      img {
        width: 2.55rem;
        @media (max-width: $phone) {
          width: 2.56rem;
        }
      }
    }
  }

  .guild-wrap {
    padding: 0.66rem 1.04rem 0.81rem;
    position: relative;
    width: 12rem;
    margin: 2.88rem auto 1rem;
    border-radius: 0.2rem;
    border: 1px solid #ffffff;
    backdrop-filter: blur(40px);
    @media (max-width: $phone) {
      width: 7.1rem;
      padding: 0.9rem 0.34rem 0.68rem 0.62rem;
      margin-top: 2.22rem;
    }
    &::after {
      content: '';
      position: absolute;
      left: 0.92rem;
      top: 0.66rem;
      border-left: 1px dashed rgba(255, 255, 255, 0.5);
      height: calc(100% - 1.47rem);
      @media (max-width: $phone) {
        left: 0.38rem;
      }
    }
    .step-header {
      @include flexPos(space-between, center);
      padding-bottom: 0.1rem;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      > img {
        width: 0.8rem;
        @media (max-width: $phone) {
          font-size: 0.56px;
        }
      }
      .desc {
        padding-left: 13px;
        flex-direction: column;
        flex: 1;
        @include flexPos(center, flex-start);
        @media (max-width: $phone) {
          padding-left: 0px;
        }
        .title {
          font-size: 0.28rem;
          color: #ffffff;
          position: relative;
          @media (max-width: $phone) {
            font-size: 0.36rem;
            font-weight: 600;
          }
          &::before {
            content: '';
            position: absolute;
            left: -10px;
            top: 0;
            transform: translateX(-100%);
            background: url('~@img/activity/guild/circle.png') no-repeat;
            background-size: 100%;
            width: 0.3rem;
            height: 0.3rem;
            z-index: 99;
            @media (max-width: $phone) {
              width: 0.2rem;
              height: 0.2rem;
              left: -0.14rem;
            }
          }
        }
        .tip {
          @include flexPos(space-between, center);
          flex: 1;
          width: 100%;
          span:nth-child(1) {
            font-size: 0.24rem;
            color: #ffffff;
            margin-right: 0.49rem;
            @media (max-width: $phone) {
              font-size: 0.28rem;
              font-weight: 600;
            }
          }
          span:nth-child(2) {
            font-size: 0.18rem;
            color: #ffffff;
            padding-right: 6px;
            @media (max-width: $phone) {
              font-size: 12px;
            }
          }
        }
      }
    }

    .content {
      padding-top: 0.18rem;
      padding-left: 0.13rem;
      padding-bottom: 0.31rem;
      line-height: 0.32rem;
      font-size: 0.18rem;
      max-width: 7.29rem;
      @media (max-width: $phone) {
        font-size: 0.28rem;
        line-height: 0.4rem;
        max-width: 5.28rem;
        padding: 0.14rem 0px 0.54rem 0px;
      }
    }

    .btn-group {
      display: flex;
      padding-left: 0.13rem;
      a:nth-child(1) {
        margin-right: 0.58rem;
        @media (max-width: $phone) {
          margin-right: 29px;
        }
      }
    }

    .step-1 {
      padding-top: 0.41rem;
      .btn-group {
        @media (max-width: $phone) {
          padding-left: 0px;
        }
      }
    }
    .step-2 {
      margin-top: 1.08rem;
      margin-bottom: 0.81rem;
      @media (max-width: $phone) {
        margin-top: 0.82rem;
      }
      .btn-group {
        @media (max-width: $phone) {
          padding-left: 0px;
        }
      }
      .btn-group a:nth-child(1) {
        width: auto;
        padding: 0 0.32rem 0 0.36rem;
      }
      .btn-link {
        @media (max-width: $phone) {
          padding: 0 0.5rem !important;
          margin-right: 0 !important;
        }
      }
    }
    .step-3 {
      .btn-group {
        @media (max-width: $phone) {
          padding-left: 0.7rem;
        }
      }
    }

    .step-3-task {
      position: relative;
      padding-top: 0.2rem;
      padding-left: 0.13rem;
      @media (max-width: $phone) {
        padding-left: 0;
      }
      .arrow-wrap {
        position: absolute;
        left: 0.23rem;
        top: 0.28rem;
        height: calc(1.54rem - 25px);
        @media (max-width: $phone) {
          top: 0.8rem;
          padding-left: 15px;
          left: 5px;
          height: calc(100% - 0.6rem);
        }
        .line {
          position: absolute;
          left: 10px;
          top: 0;
          width: 1px;
          height: 100%;
          background-color: rgba(255, 255, 255, 0.5);
        }
        .triangle {
          position: absolute;
          left: 5px;
          bottom: 0;
          width: 0;
          height: 0;
          transform: translateY(50%);
          border: 5px solid transparent;
          border-top: 10px solid #8a83ac;
        }
      }
      .sub-title {
        font-size: 18px;
        font-weight: 600;
        color: #ffffff;
        line-height: 0.24rem;
        @media (max-width: $phone) {
          font-size: 0.28rem;
          line-height: 0.4rem;
        }
      }
      .step-desc {
        padding-left: 0.78rem;
        color: #ffffff;
        line-height: 0.24rem;
        font-size: 18px;
        margin-bottom: 20px;
        max-width: 6.98rem;
        @media (max-width: $phone) {
          font-size: 0.28rem;
          line-height: 0.4rem;
          padding-left: 35px;
          padding-top: 5px;
        }
      }
      .btn-link {
        margin-left: 0.78rem;
      }
      .btn-group .btn-link {
        @media (max-width: $phone) {
          margin-left: 0;
        }
      }

      .task-item {
        position: relative;
        margin-bottom: 0.52rem;
        > .btn-link {
          @media (max-width: $phone) {
            margin-left: 0.7rem;
          }
        }
      }

      .badge {
        position: absolute;
        right: 0;
        bottom: 0;
        @media (max-width: $phone) {
          position: initial;
          margin-bottom: 0.38rem;
        }
        img {
          width: 0.98rem;
          @media (max-width: $phone) {
            width: 0.98rem;
            margin: 0 auto;
          }
        }
      }

      .btn-group {
        padding-left: 0;
        @media (max-width: $phone) {
          padding-left: 0.7rem;
        }

        .btn-link:nth-child(2) {
          margin-left: 0.58rem;
        }
      }
    }
  }

  .user-wrap {
    padding: 0.66rem 1.04rem 0.81rem;
    position: relative;
    width: 12rem;
    margin: 2.88rem auto 1rem;
    border-radius: 0.2rem;
    border: 1px solid #ffffff;
    backdrop-filter: blur(40px);
    @media (max-width: $phone) {
      width: 7.1rem;
      padding: 0.9rem 0.34rem 0.54rem 0.62rem;
      margin-top: 2.22rem;
    }
    &::after {
      content: '';
      position: absolute;
      left: 0.92rem;
      top: 0.66rem;
      border-left: 1px dashed rgba(255, 255, 255, 0.5);
      height: calc(100% - 1.47rem);
      @media (max-width: $phone) {
        left: 0.38rem;
      }
    }

    .step-header {
      @include flexPos(space-between, center);
      padding-bottom: 0.1rem;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      > img {
        width: 0.8rem;
        @media (max-width: $phone) {
          font-size: 0.56px;
        }
      }
      .desc {
        padding-left: 0.13rem;
        flex-direction: column;
        @include flexPos(center, flex-start);
        @media (max-width: $phone) {
          padding-left: 0px;
        }
        .title {
          font-size: 28px;
          color: #ffffff;
          position: relative;
          @media (max-width: $phone) {
            font-size: 18px;
            font-weight: 600;
          }
          &::before {
            content: '';
            position: absolute;
            left: -10px;
            top: 0;
            transform: translateX(-100%);
            background: url('~@img/activity/guild/circle.png') no-repeat;
            background-size: 100%;
            width: 0.3rem;
            height: 0.3rem;
            z-index: 99;
            @media (max-width: $phone) {
              width: 0.2rem;
              height: 0.2rem;
              left: -0.14rem;
            }
          }
        }
        .tip {
          span:nth-child(1) {
            font-size: 0.24rem;
            color: #ffffff;
            margin-right: 0.49rem;
            @media (max-width: $phone) {
              font-size: 14px;
              font-weight: 600;
            }
          }
          span:nth-child(2) {
            font-size: 18px;
            color: #ffffff;
            @media (max-width: $phone) {
              font-size: 12px;
            }
          }
        }
      }
    }

    .content {
      padding-top: 0.18rem;
      padding-left: 0.13rem;
      padding-bottom: 0.31rem;
      line-height: 0.32rem;
      font-size: 0.18rem;
      max-width: 7.29rem;
      @media (max-width: $phone) {
        font-size: 12px;
        line-height: 0.5rem;
        padding: 7px 0 0.34rem;
      }
    }

    .btn-group {
      display: flex;
      padding-left: 13px;
      a:nth-child(1) {
        margin-right: 0.58rem;
        @media (max-width: $phone) {
          margin-right: 0.54rem;
        }
      }
    }

    .step-1 {
      margin-bottom: 0.76rem;
      @media (max-width: $phone) {
        margin-bottom: 0.54rem;
      }
      .btn-group {
        a {
          margin-right: 0;
        }
        .btn-link:nth-child(2) {
          margin-left: 0.58rem;
        }
      }
    }
    .step-2 {
      .badge-group {
        padding-top: 0.6rem;
        padding-left: 0.89rem;
        padding-bottom: 0.5rem;
        @media (max-width: $phone) {
          padding: 0.54rem 0 0.36rem;
        }
        img {
          width: 0.98rem;
          @media (max-width: $phone) {
            margin: 0 auto;
            width: 1rem;
          }
        }
      }
      .btn-group {
        padding-bottom: 0.19rem;
        @media (max-width: $phone) {
          padding-bottom: 0;
          padding-left: 0;
        }
        .btn-link {
          @media (max-width: $phone) {
            margin: 0 auto;
          }
        }
      }
    }
  }
}
</style>
