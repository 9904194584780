<script setup>
import { ref, reactive, computed, watchEffect } from "vue";
import { useAppStore } from "@store/appStore";
import useCattleStore from "@contractStore/cattleStore.js";
import useYohoNftStore from "@contractStore/yohoNftStore.js";
import useCattleExchangeStore from "@contractStore/cattleExchangeStore.js";
import { ElMessage } from "element-plus";
import $load from "@cps/GlobalLoading";
import { useI18n } from "vue-i18n";
import router from '@/router';
import { decimalOperations } from "@/utils/tools.js"

const SWAP_MULTIPLE = 5;
const $tx = useI18n();
const NFT_LEVEL = {
  "Cool Blue": 1,
  "Phantom Silver": 2,
  "Eternal Diamond": 3,
  "Radiant Crown": 4,
  "Luxury Gold": 5,
};

const appStore = useAppStore();
const cattleStore = useCattleStore();
const yohoNftStore = useYohoNftStore();
const cattleExchangeStore = useCattleExchangeStore();
const surplusCount = ref(0);
const myNftCount = ref(0);
const swapCount = ref(null);
const swapNftCount = ref(0);
const disabledBtn = ref(true);
cattleStore.createContract();
yohoNftStore.createContract();
cattleExchangeStore.createContract();

watchEffect(async () => {
  await init();
});

async function init() {
  swapCount.value = null
  swapNftCount.value = 0
  const count = await cattleStore.mintersCreation();
  surplusCount.value = count;

  if (appStore.defaultAccount == null) return;
  const nftCount = await yohoNftStore.getBalanceOfNft();
  console.log("🚀 ~ init ~ nftCount:", nftCount);
  myNftCount.value = nftCount;
}

const handleChangeCount = (e) => {
  const { value } = e.target || {}
  const inputNum = Number(value);
  const isEmpty = value === ''

  if (e.data === 'e') {
    e.returnValue = false;
    e.target.value = swapCount.value || '';
    return;
  }
  const reg = /^[1-9]\d*$/; // 正则表达式，匹配以1-9开头的数字字符串
  if (!isEmpty && (isNaN(inputNum) || !reg.test(value))) {
    ElMessage({
      message: $tx.t("swap.21"),
      type: "error",
    });
    return;
  }
  if (!isEmpty && inputNum > surplusCount.value) {
    ElMessage({
      message: $tx.t("swap.23"),
      type: "error",
    });
    disabledBtn.value = true
    return;
  }
  const needCount = !isEmpty ? decimalOperations(inputNum, SWAP_MULTIPLE, '*') : 0
  if (needCount > myNftCount.value) {
    ElMessage({
      message: $tx.t("swap.22"),
      type: "error",
    });
    e.target.value = swapCount.value;
    disabledBtn.value = !swapCount.value
    return;
  }
  disabledBtn.value = !value
  swapCount.value = isEmpty ? '' : inputNum;
  swapNftCount.value = needCount
};

const handleConfirm = async () => {
  const inputNum = parseInt(swapCount.value);
  if (!inputNum || inputNum < 0) {
    ElMessage({
      message: $tx.t("swap.21"),
      type: "error",
    });
    return;
  }
  const nftCount = await yohoNftStore.getBalanceOfNft();
  if (inputNum * SWAP_MULTIPLE > nftCount) {
    ElMessage({
      message: $tx.t("swap.22"),
      type: "error",
    });
    return;
  }
  const count = await cattleStore.mintersCreation();
  if (inputNum > count) {
    ElMessage({
      message: $tx.t("swap.23"),
      type: "error",
    });
    return;
  }
  if (disabledBtn.value) return

  $load({ isShow: true });
  const isApproved = await yohoNftStore.isApprovedAll();
  let approvalRes = undefined;
  if (!isApproved) {
    approvalRes = await yohoNftStore.setApprovalForAll();
  }
  (isApproved || approvalRes?.hash) && handleSubmit(inputNum);
};

const handleSubmit = async (amount) => {
  // 要获取的 tokenId 列表
  const tokenIdList = await yohoNftStore.getUserNFTs();
  Promise.all(tokenIdList.map((tokenId) => getTokenURI(tokenId)))
    .then((tokenURIs) => {
      const jsonPromises = tokenURIs.map((tokenURI, index) =>
        getJSONFromURL(tokenURI).then((jsonResult) => {
          jsonResult.tokenId = tokenIdList[index];
          return jsonResult;
        })
      );
      return Promise.all(jsonPromises);
    })
    .then(async (jsonResults) => {
      // 提取属性值为 Level 的对象，并根据 NFT_LEVEL 排序
      const sortedData = jsonResults.sort(
        (a, b) =>
          NFT_LEVEL[a.attributes.find((attr) => attr.name === "Level").value] -
          NFT_LEVEL[b.attributes.find((attr) => attr.name === "Level").value]
      );
      const tokenIds = [];
      sortedData.slice(0, amount * SWAP_MULTIPLE).forEach((el) => {
        tokenIds.push(el.tokenId);
      });
      const resp = await cattleExchangeStore.exchangeNft(amount, tokenIds);

      if (resp?.hash) {
        init();
        $load({ isShow: false });
        ElMessage({
          message: $tx.t("swap.24"),
          type: "success",
        });
      }
    })
    .catch((error) => {
      ElMessage({
        message: $tx.t("swap.25"),
        type: "error",
      });
      console.error("发生错误：", error);
      $load({ isShow: false });
    })
};

const inProgress = computed(() => {
  return surplusCount.value > 0;
});

function getTokenURI(tokenId) {
  return new Promise((resolve, reject) => {
    resolve(yohoNftStore.getTokenURI(tokenId));
  });
}

function getJSONFromURL(url) {
  return fetch(url).then((response) => response.json());
}

const  handleJump = () => {
  router.push({ name: 'exchageRecord' });
}

const handleBack = () => {
  router.back()
}
</script>

<template>
  <div class="swap-container">
    <div class="overlay"></div>
    <!-- 在此处添加您的 HTML 内容 -->
    <div class="content">
      <section class="hd-title">
        <div class="title">{{ $tx.t("swap.1")}}</div>
      </section>
      <div class="bridge-box"></div>
      <section class="swap-box">
        <template v-if="inProgress">
          <p class="balance">{{ $tx.t("swap.2")}}：{{surplusCount}}</p>
          <p>{{ $tx.t("swap.3")}}：{{myNftCount}}</p>
          <p>{{ $tx.t("swap.4")}}</p>
          <div class="input-box">
            <input
              type="number"
              class="input-num"
              :value="swapCount"
              @input="handleChangeCount"
              
            />
            <div :class="['confirm-btn', disabledBtn ? 'disabled-mask' : '']" @click="handleConfirm">{{ $tx.t("swap.5")}}</div>
          </div>
          <p v-if="swapNftCount" class="need-text">{{ $tx.t("swap.18")}}：{{swapNftCount}}</p>
        </template>

        <div v-else class="empty-box">{{ $tx.t("swap.7")}}</div>
        <div class="history flex_center" @click="handleJump">{{ $tx.t("swap.6")}}</div>
      </section>
      <div class="bridge-box"></div>
      <template v-if="appStore.curDevice === 'phone'">
        <section class="active-desc">
          {{ $tx.t("swap.8")}}
          <p v-html="$tx.t('swap.9')"></p>
        </section>
        <section class="tip-box">
          <div class="tip-box-title">{{ $tx.t("swap.10")}}</div>
         <p class="mrg-t-10" v-html="$tx.t('swap.11')"></p>
        </section>
        <section class="tip-box">
          <div class="tip-box-title">{{ $tx.t("swap.12")}}</div>
          <p class="mrg-t-20" v-html="$tx.t('swap.13')"></p>
        </section>
        <section class="tip-box">
          <div class="tip-box-title">{{ $tx.t("swap.14")}}</div>
          <p class="mrg-t-15" v-html="$tx.t('swap.15')"></p>
          <p v-html="$tx.t('swap.16')"></p>
        </section>
      </template>
      <template v-else>
        <section class="active-desc">
          <p class="sub-title">{{ $tx.t("swap.8")}}</p>
          <p v-html="$tx.t('swap.9')"></p>

          <p class="sub-title">{{ $tx.t("swap.10")}}：</p>
          <p v-html="$tx.t('swap.11')"></p>

          <p class="sub-title">{{ $tx.t("swap.12")}}：</p>
           <p v-html="$tx.t('swap.13')"></p>

          <p class="sub-title">{{ $tx.t("swap.14")}}：</p>
          <p v-html="$tx.t('swap.15')"></p>

          <p class="tips">
          <p v-html="$tx.t('swap.16')"></p>

          </p>
        </section>
        <div class="bridge-box-last"></div>
      </template>
      <img src="@img/swap/footer_bg_pc.png" alt="" class="footer-box" />
      <div
        v-if="appStore.curDevice === 'phone'"
        class="back-btn flex_center"
        @click="handleBack"
      >{{$tx.t('dialog.3')}}</div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@media (max-width: 768px) {
  /* 移动端样式 */
  .swap-container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    background-image: url("../../../assets/img/swap/swap-bg.webp");
    background-repeat: no-repeat;
    background-size: 100% 100%;

    .bridge-box {
      height: 70px;
      margin: -20px 60px -30px;
      background-image: url("../../../assets/img/swap/bridge.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      z-index: 10;
    }
    .overlay {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      background: rgba(5, 2, 27, 0.8);
    }

    .content {
      position: relative;
      display: flex;
      flex-direction: column;
      padding: 36px 12px;
      z-index: 1;
      .hd-title {
        width: 100%;
        height: 180px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: url("../../../assets/img/swap/swap_title.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;

        .title {
          position: relative;
          font-weight: 600;
          font-size: 24px;
          line-height: 24px;
          color: #fef76a;
          text-shadow: 2px 2px 0px #983900;

          &::before {
            content: attr(data-text);
            position: absolute;
            top: 0;
            left: 0;
            z-index: 10;
            text-shadow: none;
            background: linear-gradient(
              89.9999979403deg,
              #fef76a 0%,
              #f9dd17 100%
            );
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
      }

      .swap-box {
        position: relative;
        width: 100%;
        min-height: 260px;
        padding: 40px 22px;
        background-image: url("../../../assets/img/swap/info_bg.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;

        .empty-box {
          height: 130px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 18px;
          color: #fff;
          font-weight: bold;
          line-height: 27px;
        }
        p {
          margin-bottom: 8px;
          font-size: 14px;
          color: rgba(255, 255, 255, 0.8);
        }
        .balance {
          margin-bottom: 15px;
          font-weight: bold;
          font-size: 18px;
          color: #ffffff;
          line-height: 27px;
        }
        .input-box {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .input-num {
          flex: 1;
          height: 36px;
          padding: 0 10px;
          background: #ffffff;
          box-shadow: inset 2px 2px 2px 0px rgba(150, 85, 64, 0.4),
            2px 2px 2px 0px rgba(0, 0, 0, 0.2);
          border-radius: 5px 5px 5px 5px;
          border: none;
          font-size: 16px;
        }
        .need-text {
          padding: 10px 0;
        }
        .confirm-btn {
          position: relative;
          margin-left: 15px;
          padding: 9px;
          width: 74px;
          height: 38px;
          color: #b0621d;
          font-weight: bold;
          background-image: url("../../../assets/img/swap/confirm_btn.png");
          background-repeat: no-repeat;
          background-size: 100% 100%;
          background-position-y: bottom;
          text-align: center;
          cursor: pointer;
        }
        .disabled-mask::after {
          content: "";
          position: absolute;
          top: 0px;
          left: 2px;
          width: 70px;
          height: 33px;
          background-color: rgba(0, 0, 0, 0.5);
          z-index: 1;
          border-radius: 50%/100% 100% 100% 100%;
        }

        .history {
          position: absolute;
          bottom: 20px;
          left: 50%;
          transform: translateX(-50%);
          padding: 5px 12px;
          min-width: 112px;
          background: #8a4d32;
          box-shadow: inset 0px 1px 1px 0px rgba(255, 255, 255, 0.15),
            inset 0px -2px 2px 0px rgba(0, 0, 0, 0.15);
          border-radius: 15px 15px 15px 15px;
          font-weight: 500;
          font-size: 12px;
          color: #fdcaa2;
          line-height: 18px;
          z-index: 100;
          cursor: pointer;
        }
      }

      .active-desc {
        width: 100%;
        padding: 40px 24px;
        background-image: url("../../../assets/img/swap/desc_bg.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        font-size: 14px;
        color: #fff;
        line-height: 21px;
      }

      .tip-box {
        margin-top: 15px;
        position: relative;
        width: 100%;
        padding: 50px 24px;
        background-image: url("../../../assets/img/swap/tip_bg.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        color: #fff;
        font-size: 14px;
        line-height: 21px;
        .tip-box-title {
          position: absolute;
          top: 6%;
          left: 50%;
          transform: translateX(-50%);
          color: #93553d;
          font-size: 16px;
          font-weight: bold;
        }

        & + .tip-box {
          margin-top: 20px;
        }
      }

      .footer-box {
        margin-top: 20px;
      }

      .back-btn {
        margin-top: 50px;
        width: 100%;
        height: 44px;
        color: #b0621d;
        font-weight: bold;
        background-image: url("../../../assets/img/swap/back_btn.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }
    }
  }
}

@media (min-width: 769px) {
  .swap-container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    background-image: url("../../../assets/img/swap/swap-bg-pc.webp");
    background-repeat: no-repeat;
    background-size: 100% 100%;

    .bridge-box {
      height: 180px;
      width: 600px;
      margin: -50px auto -80px;
      background-image: url("../../../assets/img/swap/bridge_pc_2.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      z-index: 10;
    }

    .bridge-box:nth-child(2) {
      background-image: url("../../../assets/img/swap/bridge_pc.png");
    }

    .bridge-box-last {
      height: 180px;
      width: 600px;
      margin: -100px auto -90px;
      background-image: url("../../../assets/img/swap/bridge_pc_2.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      z-index: 10;
    }

    .overlay {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      background: rgba(5, 2, 27, 0.8);
    }

    .content {
      position: relative;
      display: flex;
      flex-direction: column;
      padding: 100px 19%;
      z-index: 1;
      .hd-title {
        width: 100%;
        height: 400px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: url("../../../assets/img/swap/swap_title_pc.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;

        .title {
          position: relative;
          font-weight: 600;
          font-size: 72px;
          line-height: 72px;
          color: #fef76a;
          text-shadow: 2px 2px 0px #983900;

          &::before {
            content: attr(data-text);
            position: absolute;
            top: 0;
            left: 0;
            z-index: 10;
            text-shadow: none;
            background: linear-gradient(
              89.9999979403deg,
              #fef76a 0%,
              #f9dd17 100%
            );
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
      }

      .swap-box {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        min-height: 600px;
        padding: 110px 22px 0;
        background-image: url("../../../assets/img/swap/info_bg_pc.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;

        .empty-box {
          height: 280px;
          display: flex;
          align-items: center;
          font-size: 36px;
          color: #fff;
          font-weight: bold;
          line-height: 54px;
        }

        p {
          margin-bottom: 18px;
          font-size: 26px;
          color: rgba(255, 255, 255, 0.8);
        }
        .balance {
          margin-bottom: 40px;
          font-weight: bold;
          font-size: 42px;
          color: #ffffff;
          line-height: 63px;
        }
        .input-box {
          position: relative;
        }
        .input-num {
          width: 422px;
          height: 72px;
          font-size: 32px;
          padding: 0 20px;
          background: #ffffff;
          box-shadow: inset 4px 4px 4px 0px rgba(150, 85, 64, 0.4),
            4px 4px 4px 0px rgba(0, 0, 0, 0.2);
          border-radius: 10px 10px 10px 10px;
          border: none;
        }
        .need-text {
          padding: 15px 0;
        }
        .confirm-btn {
          position: absolute;
          padding: 15px;
          top: 4px;
          right: -175px;
          width: 145px;
          height: 72px;
          color: #b0621d;
          font-size: 24px;
          font-weight: bold;
          background-image: url("../../../assets/img/swap/confirm_btn.png");
          background-repeat: no-repeat;
          background-size: 100% 100%;
          text-align: center;
        }
        .disabled-mask::after {
          content: "";
          position: absolute;
          top: 0px;
          left: 5px;
          width: 135px;
          height: 63px;
          background-color: rgba(0, 0, 0, 0.5);
          z-index: 1;
          border-radius: 50%/100% 100% 100% 100%;
        }

        .history {
          position: absolute;
          bottom: 60px;
          left: 50%;
          transform: translateX(-50%);
          min-width: 228px;
          padding: 19px 24px;
          background: #8a4d32;
          box-shadow: inset 0px 2px 2px 0px rgba(255, 255, 255, 0.15),
            inset 0px -2px 2px 0px rgba(0, 0, 0, 0.15);
          border-radius: 30px 30px 30px 30px;
          font-weight: 500;
          font-size: 24px;
          color: #fdcaa2;
          line-height: 18px;
          z-index: 100;
          cursor: pointer;
        }
      }

      .active-desc {
        width: 100%;
        // height: 1450px;
        padding: 100px 110px 150px;
        background-image: url("../../../assets/img/swap/desc_bg_pc.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        color: rgba(255, 255, 255, 0.8);
        font-size: 26px;
        line-height: 39px;
        text-align: left;
        font-style: normal;
        text-transform: none;
        .sub-title {
          margin-top: 30px;
          margin-bottom: 16px;
          font-weight: bold;
          font-size: 32px;
          color: #ffffff;
          line-height: 38px;
          text-align: left;
          font-style: normal;
          text-transform: none;
        }

        .tips {
          margin-top: 30px;
          font-weight: bold;
          font-size: 26px;
          color: #ffffff;
          line-height: 39px;
          text-align: left;
          font-style: normal;
          text-transform: none;
        }
      }

      .footer-box {
        margin-top: 20px;
      }
    }
  }
}

/* 公共样式 */
.flex_between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flex_center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.mrg-t-10 {
  margin-top: 10px;
}
.mrg-t-15 {
  margin-top: 15px;
}
.mrg-t-20 {
  margin-top: 20px;
}
</style>