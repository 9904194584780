import { useAppStore } from '@store/appStore';
import { toRaw } from 'vue';
import { GUILD_CONT } from '@/contracts/polygonAddress';
import { ElMessage } from 'element-plus';
import useDefaultRpc from '@/contracts/useDefaultRpc';
import { bpWrite, bpRead } from '@/service/bpAction';
import { bpFormat, bpDiv } from '@/utils/bpMath';
import { ethers } from 'ethers';

import i18n from '@/utils/i18n';

const $t = i18n.global.t;

export default class {
  /**
   * 构建
   * @param {Object} addressObj 地址对象：包括地址和abi 默认为盲盒合约对象
   */
  constructor(addressObj = GUILD_CONT) {
    this.craeteContract(addressObj);
  }

  /**
   * 构建对象
   * @param {Object} addressObj：包括合约地址、abi
   * 例如 去旁边的 address.js 里拿 PROXY_QUERY_CONT 传入
   * @returns 合约对象
   */
  craeteContract(addressObj) {
    const appStore = useAppStore();
    // const { ethers, signer } = appStore.ethObj;
    // console.log('window....', window.ethereum);
    const signer = useDefaultRpc();
    let contract;
    if (!appStore.defaultAccount) {
      const rpcProvider = new ethers.providers.JsonRpcProvider('https://polygon-rpc.com');
      contract = new ethers.Contract(addressObj.address, addressObj.abi, toRaw(rpcProvider));
    } else {
      contract = new ethers.Contract(addressObj.address, addressObj.abi, toRaw(signer));
    }
    // const contract = new ethers.Contract(addressObj.address, addressObj.abi, toRaw(signer));
    this.contract = contract;
    this.defaultAccount = appStore.defaultAccount;
    return this.contract;
  }

  /**
   * 获取用户推荐信息
   */
  async getUserInfo(address = this.defaultAccount) {
    const resp = await this.contract.userInfo(address);
    return {
      isRefer: resp.isRefer,
      referAmount: +resp.referAmount,
      claimed: +bpFormat(resp.claimed, 0, 6),
    };
  }

  /**
   * 领取奖励
   */
  async claimReward(index) {
    const resp = await bpWrite($t('msg.7'), this.contract.claimReward, index);
    return resp;
  }

  /**
   * 查看用户可领取奖励
   */
  async getUserToClaim() {
    const toChaim = await this.contract.getUserToClaim(this.defaultAccount);
    // 待领取
    return +bpFormat(toChaim, 0, 6);
  }

  /**
   * 白名单数量
   */
  async totalWhite() {
    const count = await this.contract.totalWhite();
    return +count;
  }
}
